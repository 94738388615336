export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647887089/naosushi/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1590261442/general/cflogo.png'
export const aboutText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589305503/amigopancake/aboutText.png'
export const headerBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589305503/amigopancake/headerBg.jpg'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647635942/naosushi/about.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647636324/naosushi/contactbg.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647955850/naosushi/foodGallery.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647635069/naosushi/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1631230035/jlsushi/heroText3.png'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/homebg.jpg'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647636258/naosushi/locationMap.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647635128/naosushi/logo.png'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/logoTitle.png'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647637928/naosushi/mobileAbout.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647638848/naosushi/mobileContact.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647955850/naosushi/mobileGallery.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647887695/naosushi/mobileHero2.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1631230035/jlsushi/mobileHeroText3.png'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647638699/naosushi/mobileMap.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647637784/naosushi/mobilePromo.jpg'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1600214715/jlsushi/orderDeliveryButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647635412/naosushi/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1647637429/naosushi/promotions.jpg'
export const menu =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1683479708/naosushi/NaoMenu.pdf'
